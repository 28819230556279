<template>
  <div class="ClientAccessDetail">

    <loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      color="black">
    </loading>

    <CModal
      :show.sync="isClientAccessModified"
      :no-close-on-backdrop="true"
      title="Accès client modifié"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        Cet accès client a bien été modifié 👍
      </p>
      <p>
        Attention à bien prévenir votre client de ces modifications.
      </p>
      <template #footer>
        <CButton @click="$router.push('/client-details/' + client.id)" color="primary">Retour au profil client</CButton>
        <CButton @click="isClientAccessModified = false" color="dark">Continuer les modifications</CButton>
      </template>
    </CModal>
    <CModal
      :show.sync="isClientAccessDeleted"
      :no-close-on-backdrop="true"
      title="Accès client révoqué"
      size="lg"
      color="dark"
    >
      <p class="text-center" style="font-size: 1.2em; font-weight: bold;">
        ⛔ Accès client révoqué ⛔
      </p>
      <p>
        Votre client ne pourra plus se connecter sur la plateforme Elerk.
      </p>
      <template #footer>
        <CButton @click="$router.push('/client-details/' + client.id)" color="dark">Retour au profil client</CButton>
      </template>
    </CModal>

    <CRow>
      <CCol sm="6">
        <h1> Accès de votre client à Eclerk </h1>
      </CCol>
      <CCol class="text-right align-self-center" sm="6">
        <CButton
          @click="goBackToClientProfile"
          size="sm"
          shape="pill"
          color="outline-dark">
            <CIcon name="cil-arrow-left"/> Retour au profil client
        </CButton>
      </CCol>
    </CRow>

    <CCard v-if="client.user" class="mt-3">

      <CCardBody>

        <CForm>
          <CInput
            label="Identifiant du client"
            type="text"
            maxlength="149"
            placeholder="Identifiant de votre client pour se connecter"
            v-model="modifyClientAccessUsername"
            @input="$v.modifyClientAccessUsername.$touch(); isExistingUsername == false"
            :isValid="$v.modifyClientAccessUsername.$dirty ? !$v.modifyClientAccessUsername.$error : null"
          >
            <template #description>
              <small v-if="isExistingUsername" class="text-danger">
                {{ modifyClientAccessUsername }} existe déjà. Veuillez choisir un autre nom d'utilisateur
              </small>
            </template>
          </CInput>

          <CRow>
            <CCol sm="6">
              <CInput
                label="Prénom du responsable de connexion chez votre client"
                v-model="modifyClientAccessFirstName" type="text"
                maxlength="30"
                placeholder="Prénom"
                @input="$v.modifyClientAccessFirstName.$touch()"
                :isValid="$v.modifyClientAccessFirstName.$dirty ? !$v.modifyClientAccessFirstName.$error : null"
                invalid-feedback="Le prénom doit comporter entre 2 et 30 caractères"
              >
              </CInput>
            </CCol>
            <CCol sm="6">
              <CInput
                label="Nom du responsable de connexion chez votre client"
                v-model="modifyClientAccessLastName" type="text"
                maxlength="150"
                placeholder="Nom"
                @input="$v.modifyClientAccessLastName.$touch()"
                :isValid="$v.modifyClientAccessLastName.$dirty ? !$v.modifyClientAccessLastName.$error : null"
                invalid-feedback="Le nom doit comporter entre 2 et 150 caractères"
              >
              </CInput>
            </CCol>
          </CRow>

          <CInput
            label="Email du responsable de connexion chez votre client"
            type="email"
            maxlength="150"
            placeholder="Email"
            v-model="modifyClientAccessEmail"
            @input="$v.modifyClientAccessEmail.$touch();"
            :isValid="$v.modifyClientAccessEmail.$dirty ? !$v.modifyClientAccessEmail.$error : null"
          >
            <template #description>
              <small class="text-info"> En cas d'oubli de mot de passe ou d'envoi de mails automatiques</small>
            </template>
          </CInput>

          <CRow class="mt-4">
            <CCol sm="6">
              <CButton
                :color="modifyClientAccessButtonStyle"
                shape="pill" block class="px-4"
                @click='modifyClientAccess'
                :disabled="$v.modifyClientAccessUsername.$invalid || $v.modifyClientAccessFirstName.$invalid || $v.modifyClientAccessLastName.$invalid || $v.modifyClientAccessEmail.$invalid || modifyClientAccessInProcess">
                <CSpinner size="sm" label="modify client access spinner" v-if="modifyClientAccessInProcess"></CSpinner>
                {{ modifyClientAccessButtonText }}
              </CButton>
            </CCol>
            <CCol sm="6">
              <CButton
                :color="deleteClientAccessButtonStyle"
                shape="pill" block class="px-4"
                @click='deleteClientAccess'
                :disabled="deleteClientAccessInProcess">
                <CSpinner size="sm" label="archive client spinner" v-if="deleteClientAccessInProcess"></CSpinner>
                {{ deleteClientAccessButtonText }}
              </CButton>
              <small class="text-danger">Attention, si vous révoquez cet accès client, votre client ne pourra plus accéder à l'application via son compte.</small>
            </CCol>
          </CRow>
        </CForm>

      </CCardBody>
    </CCard>

    <CCard v-else>
      <CCardHeader>
        <slot name="header">
          <strong>Accès de votre client révoqué</strong>
        </slot>
      </CCardHeader>

      <CCardBody>

        <CRow>
          <CCol class="text-center">
            <strong class="text-danger"> L'accès de votre client a été révoqué </strong>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="text-center">
            <CButton @click="$router.push('/client-details/' + client.id)" color="outline-primary" shape="pill" class="mt-4" block>Retour au profil de votre client</CButton>
          </CCol>
        </CRow>
      </CCardBody>
    </CCard>

  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import userMixins from '@/mixins/userMixins'
import { required, minLength, maxLength, email} from 'vuelidate/lib/validators'

import { APIUserConnected } from '@/api/APIUserConnected'
import { APIManagerConnected } from '@/api/APIManagerConnected'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiUserConnected = new APIUserConnected()
const apiManagerConnected = new APIManagerConnected()


export default {
  name: 'ClientAccessDetail',
  components: {
    Loading,
  },
  mixins: [
    validationMixin,
    userMixins
  ],
  data: function () {
    return {

      // ---- CLIENT ---------
      isClientLoading: false,
      client: {
        address: '',
        city: '',
        email: '',
        id: '',
        is_firm: true,
        name: '',
        phone_number: '',
        representant_legal: '',
        siren: '',
        user: null
      },

      modifyClientAccessUsername: '',
      modifyClientAccessFirstName: '',
      modifyClientAccessLastName: '',
      modifyClientAccessEmail: '',

      modifyClientAccessButtonText: "Modifier les paramètres d'accès",
      modifyClientAccessButtonStyle: 'outline-primary',
      modifyClientAccessInProcess: false,
      isExistingUsername: false,

      deleteClientAccessButtonText: "Révoquer cet accès client",
      deleteClientAccessButtonStyle: 'outline-danger',
      deleteClientAccessInProcess: false,

      isClientAccessModified: false,
      isClientAccessDeleted: false

    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isLoading () {
      if (this.isClientLoading) {
        return true
      }
      return false
    },
  },
  validations: {
    modifyClientAccessUsername: {
      required,
      minLength: minLength(5),
      maxLength: maxLength(149)
    },
    modifyClientAccessEmail: {
      required,
      email,
      maxLength: maxLength(249),
      minLength: minLength(2)
    },
    modifyClientAccessFirstName: {
      required,
      maxLength: maxLength(30),
      minLength: minLength(2)
    },
    modifyClientAccessLastName: {
      required,
      maxLength: maxLength(150),
      minLength: minLength(2)
    },
  },

  created: function() {
    this.getClient()
  },


  methods: {
    // ------------- Getters --------------
    getClient () {
      this.isClientLoading = true
      apiUserConnected.getClient(this.token, this.$route.params.id)
      .then((result) => {
        this.client = result.data
        if (this.client.user) {
          this.modifyClientAccessUsername = this.client.user.username
          this.modifyClientAccessFirstName = this.client.user.first_name
          this.modifyClientAccessLastName = this.client.user.last_name
          this.modifyClientAccessEmail = this.client.user.email
        }

      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.isClientLoading = false
      })
    },


    // ---------- Modify access client -----------
    modifyClientAccess () {
      this.modifyClientAccessButtonText = 'Modification en cours'
      this.modifyClientAccessButtonStyle = 'secondary'
      this.modifyClientAccessInProcess = true
      apiManagerConnected.modifyClientAccess(this.token,
        this.client.id, this.modifyClientAccessUsername, this.modifyClientAccessEmail, this.modifyClientAccessFirstName, this.modifyClientAccessLastName)
      .then(() => {
        this.isClientAccessModified = true
      })
      .catch((error) => {
        try {
          if (error.response && error.response.data.username && error.response.data.username[0] === "Un utilisateur avec cet email existe déjà") {
            this.isExistingUsername = true
          }
          else {
            this.$store.commit('openGlobalErrorModal')
          }
        }
        catch {
          this.$store.commit('openGlobalErrorModal')
        }
      })
      .finally(() => {
        this.modifyClientAccessButtonText = "Modifier les paramètres d'accès"
        this.modifyClientAccessButtonStyle = 'outline-primary'
        this.modifyClientAccessInProcess = false
      })
    },

    // ---------- Delete access client -----------
    deleteClientAccess () {
      this.deleteClientAccessButtonText = 'Suppression en cours'
      this.deleteClientAccessButtonStyle = 'secondary'
      this.deleteClientAccessInProcess = true
      apiManagerConnected.deleteClientAccess(this.token, this.client.id)
      .then(() => {
        this.isClientAccessDeleted = true
        this.getClient()
      })
      .catch(() => {
        this.$store.commit('openGlobalErrorModal')
      })
      .finally(() => {
        this.deleteClientAccessButtonText = "Révoquer cet accès client"
        this.deleteClientAccessButtonStyle = 'outline-danger'
        this.deleteClientAccessInProcess = false
      })
    },


    // --- Router functions
    goBackToClientProfile () {
      this.$router.push('/client-details/' + this.client.id)
    }

  }
}
</script>
